<template>
  <div style="max-width: 1800px; margin: auto">
    <v-card v-if="locale === 'en'" class="welcome-card">
      <h2>Welcome to our shop!</h2>
      <hr style="margin-top: 10px; margin-bottom: 10px">
      <span v-html="properties.firstPageTextEN"></span>
    </v-card>
    <v-card v-else-if="locale === 'de'" class="welcome-card">
      <h2>Willkommen in unserem Shop!</h2>
      <hr style="margin-top: 10px; margin-bottom: 10px">
      <span v-html="properties.firstPageTextDE"></span>
    </v-card>
    <v-card v-else class="welcome-card">
      <h2>Dobrodošli v naši spletni trgovini!</h2>
      <hr style="margin-top: 10px; margin-bottom: 10px">
      <span v-html="properties.firstPageTextSI"></span>
    </v-card>

    <v-card class="search-card">
      <h2 v-if="locale === 'en'">Search</h2>
      <h2 v-else-if="locale === 'de'">Suchen</h2>
      <h2 v-else>Iskalnik</h2>
      <hr style="margin-top: 10px; margin-bottom: 10px">
      <div style="height: 68px">
        <v-text-field
            v-model="searchTerm"
            outlined
            :label="getSearchLabel()"
            @input="getDisplayItems()"
        ></v-text-field>
      </div>
      <hr style="margin-top: 0; margin-bottom: 10px">

      <div v-if="!loading">
        <div v-if="displayItems.length > 0">
          <v-card v-for="item in displayItems" :key="item.id" class="item-card" elevation="3" @click="$router.push('/item?id=' + item.id)">
            <div class="item-image">
              <img :src="itemImagesUrl + item.defaultImage" style="width: 100%; height: 100%; object-fit: contain">
            </div>
            <div class="text-center mt-4">
              <h4 v-if="locale === 'en'">{{ item.nameEN }}</h4>
              <h4 v-else-if="locale === 'de'">{{ item.nameDE }}</h4>
              <h4 v-else>{{ item.nameSI }}</h4>
              <div v-if="item.options.length > 1" style="padding-bottom: 30px">
                <v-chip  class="mt-3 mb-3">
                  <b style="font-size: 16px">{{ getPriceRange(item) }}</b>
                </v-chip>
                <br/>
                <small style="color: #808080" v-if="locale === 'de'">* inkl. MwSt</small>
                <small style="color: #808080" v-else-if="locale === 'en'">* VAT included</small>
                <small style="color: #808080" v-else>* DDV vključen</small>
                <br/>
                <v-btn small class=" mt-2" color="primary">
                  <span v-if="locale === 'de'"><v-icon small class="mr-1">mdi-information-outline</v-icon>Einzelheiten</span>
                  <span v-else-if="locale === 'en'"><v-icon small class="mr-1">mdi-information-outline</v-icon>Details</span>
                  <span v-else><v-icon small class="mr-1">mdi-information-outline</v-icon>Podrobnosti</span>
                </v-btn>
              </div>
              <div v-else-if="item.options.length !== 0" style="padding-bottom: 30px">
                <v-chip v-if="item.discount > 0" class="mt-3 mb-3">
                  <i style="font-size: 12px; text-decoration: line-through red ">{{ formatPrice(item.options[0].price) }} {{getUnit(item.type)}}</i>
                  <b style="font-size: 16px; margin-left: 10px; color: #ff0000">{{ applyDiscount(item.options[0].price, item.discount) }} {{getUnit(item.type)}}</b>
                </v-chip>
                <v-chip v-else class="mt-3 mb-3">
                  <b style="font-size: 16px">{{ formatPrice(item.options[0].price) }} {{getUnit(item.type)}}</b>
                </v-chip>
                <br/>
                <small style="color: #808080" v-if="locale === 'de'">* inkl. MwSt</small>
                <small style="color: #808080" v-else-if="locale === 'en'">* VAT included</small>
                <small style="color: #808080" v-else>* DDV vključen</small>
                <br/>
                <v-btn small class="mt-2 mr-2" color="primary">
                  <span v-if="locale === 'de'"><v-icon small class="mr-1">mdi-information-outline</v-icon>Einzelheiten</span>
                  <span v-else-if="locale === 'en'"><v-icon small class="mr-1">mdi-information-outline</v-icon>Details</span>
                  <span v-else><v-icon small class="mr-1">mdi-information-outline</v-icon>Podrobnosti</span>
                </v-btn>
                <!--<v-btn v-if="item.type === 'FINISHED_PRODUCT'" x-small fab class="mt-2" color="primary" @click="addToCart(item, item.options[0])">
                  <v-icon  class="">mdi-cart-arrow-down</v-icon>
                </v-btn>-->

              </div>
              <div v-if="item.discount > 0 && searchTerm !== ''" class="ribbon ribbon-top-left">
                <span v-if="locale === 'en'">DISC. -{{ item.discount }}%</span>
                <span v-else-if="locale === 'de'">AKTION -{{ item.discount }}%</span>
                <span v-else>AKCIJA -{{ item.discount }}%</span>
              </div>
              <div v-else-if="item.promoted === true" class="ribbon ribbon-top-left">
                <span v-if="locale === 'en'">BEST SELLER</span>
                <span v-else-if="locale === 'de'">BEST SELLER</span>
                <span v-else>PRIPOROČEN</span>
              </div>

            </div>
          </v-card>
        </div>
        <div v-else class="text-center" style="padding-top: 100px; padding-bottom: 80px">
          <h4 v-if="locale === 'en'">No results</h4>
          <h4 v-else-if="locale === 'de'">Keine Ergebnisse</h4>
          <h4 v-else>Ni zadetkov</h4>
        </div>
      </div>
      <div v-else class="text-center" style="padding-top: 100px; padding-bottom: 80px">
        <v-progress-circular
            :size="80"
            :width="5"
            color="#9FBB38"
            indeterminate
        ></v-progress-circular>
      </div>



    </v-card>


    <v-expand-transition>
      <v-snackbar
          v-model="snackbar"
          :timeout="4000"
          :height="80"
          :right="true"
          :color="snackbarColor"
          :elevation="24"
      >
        {{ displayMessage }}

      </v-snackbar>
    </v-expand-transition>
  </div>

</template>

<script>

//import CategoryService from "@/services/categoty.service"
import ItemsService from "@/services/items.service"
import EndpointsService from "@/services/endpoints.service"
import CartService from "@/services/cart.service";
import PropertiesService from "@/services/properties.service";

export default {
  name: "home",
  title: "ZAVESE - Domov",
  computed: {
    itemImagesUrl() {
      return EndpointsService.getApiV1Endpoint() + 'items/image/';
    },
    categoryImagesUrl() {
      return EndpointsService.getApiV1Endpoint() + 'categories/image/';
    }
  },
  data() {
    return {
      displayItems: [],
      searchTerm: "",
      loading: false,
      properties: {},

      snackbar: false,
      snackbarColor: "#9e1c1c",
      displayMessage: "",
      locale:""
    }
  },
  mounted() {
    this.getDisplayItems();
    if(localStorage.getItem("zavese-locale") === null){
      localStorage.setItem("zavese-locale", "si")
    }
    this.locale = localStorage.getItem("zavese-locale")
    this.getProperties();
  },
  methods: {

    getProperties(){
      PropertiesService.getAll().then(
          response => {
            if(response.data !== "")
              this.properties = response.data;
            else
              this.properties = {shipping: "0.0", firstPageTextSI: "", firstPageTextDE: "", firstPageTextEN: ""}
          },
          error => {
            console.log(error)
            this.displayMessage = error.response.data.message
            this.snackbarColor = "#F44336"
            this.snackbar = true
          }
      );
    },

    getSearchLabel(){
      if(this.locale === "en")
        return "write a search query here"
      else if (this.locale === "de")
        return "schreiben Sie hier eine Suchanfrage"
      else
        return "tukaj vpišite iskalni niz"
    },

    getDisplayItems(){
      this.loading = true;
      ItemsService.getSearchItems(this.searchTerm).then(
          response => {
            this.loading = false
            this.displayItems = response.data
          },
          error => {
            this.loading = false
            console.log("ERROR GETTING DISPLAY ITEMS:")
            console.log(error.response.data)
          }
      )
    },

    getPriceRange(item){
      if(item.options.length !== 0){
        var minPrice = 1000000000;
        var maxPrice = 0;
        for (var i = 0; i<item.options.length; i++){
          if(item.options[i].price > maxPrice)
            maxPrice = item.options[i].price
          if(item.options[i].price < minPrice)
            minPrice = item.options[i].price
        }
        return this.formatPrice(minPrice) + this.getUnit(item.type) + " - " + this.formatPrice(maxPrice) + this.getUnit(item.type)
      }
      else return "Error 021";
    },

    getUnit(type){
      if(type === "BLINDS")
        return "€/m²"
      else if (type === "FABRIC")
        return "€/m"
      else
        return "€"
    },

    formatPrice(number){
      return number.toFixed(2)
    },

    applyDiscount(price, discount){
      return this.formatPrice(parseFloat(price) * (1.0 - (parseFloat(discount) / 100.0)))
    },

    addToCart(itemId){
      CartService.addToLocalCart(itemId, 1);
      if(this.locale === "en") this.displayMessage = "Item successfully added to your cart!"
      else if(this.locale === "else") this.displayMessage = "Artikel erfolgreich zu Ihrem Warenkorb hinzugefügt!"
      else this.displayMessage = "Izdelek uspešno dodan v vašo košarico!"
      this.snackbar = true
    }
  }
}
</script>

<style scoped>
@import "../css/ribbon.css";

  .welcome-card{
    padding: 30px;
    padding-bottom: 40px;
    margin: 15px;
    background-color: #f3f3f3;
    max-width: 1800px;
  }
  .search-card{
    padding: 30px;
    padding-bottom: 40px;
    margin: 15px;
    background-color: #f3f3f3;
    max-width: 1800px;
  }
  .item-card {
    display: inline-block;
    width: 14%;
    margin-left: 2.3%;
    margin-top: 50px;
    background-color: #f5f5f5;
  }

  .item-image {
    border: solid 1px #d5d5d5;
    border-radius: 10px;
    margin: auto;
    width: 80%;
    aspect-ratio : 1 / 1;
    margin-top: 20px;
  }


  @media only screen and (max-width: 1800px){
    .item-card {
      width: 22.5%;
      margin-left: 2%;
    }
  }

  @media only screen and (max-width: 1450px){
    .item-card {
      width: 30%;
      margin-left: 2%;
    }
  }

  @media only screen and (max-width: 1100px){
    .item-card {
      width: 45%;
      margin-left: 3%;
    }
  }

  @media only screen and (max-width: 540px){
    .item-card {
      width: 100%;
      margin-left: 0;
    }
  }
</style>
