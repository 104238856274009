import axios from 'axios';
import EndpointService from "./endpoints.service"


const API_URL = EndpointService.getApiV1Endpoint();

class PropertiesService {

    getAll() {
        return axios.get(API_URL + "properties/get-all", { headers: {"Content-Type": "application/json; charset=UTF-8"} })
    }

}

export default new PropertiesService();
